import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Button, Icon, Upload, UploadResult, Finalize, Loader } from '../../../components/ui';
import { Input, InputSelect } from '../../../components/ui/inputs';
import dataCsv from '../../../assets/data/data.csv';
import WalletBalance from '../../wallet/components/WalletBalance';
import Schedule from '../../services/components/Schedule';
import { useUpdate } from '../../../utilities/hooks/useUpdate';
import SuccessModal from '../../wallet/components/PaymentSuccess';
import CheckOut from '../../wallet/components/CheckOut';
import {
  createDataCampaignAction,
  createSchedulledDataCampaignAction,
} from '../../../redux/actions/campaign';
import { fetchWallet } from '../../../redux/actions/wallet';
import { APIfetchOperator, APIreadFile } from '../../../api/backendRoutes';
import { fetchUserData } from '../../../storage/sessionStorage';
import { ROUTES } from '../../../components/router/routes/routes';
import GroupContactModal from '../../services/components/GroupedContactModal';

const StyledAllRecepient = styled.div`
  background-color: rgba(80, 159, 239, 0.03);
  padding: 20px;
  margin: 10px;
  margin-bottom: 60px;
`;

const DataCampaign = () => {
  const user = fetchUserData();
  const history = useHistory();
  const [showContacts, setShowContacts] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [currentRecipientBundle, setCurrentRecipientBundle] = useState({});
  const [readContact, setReadContact] = useState();
  const [failedNums, setFailedNums] = useState([]);
  const [error, setError] = useState([false, '']);
  const [price, setPrice] = useState(0);
  const [showCheckOut, setShowCheckOut] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showScheduleForLater, setShowScheduleForLater] = useState(false);
  const [recepientData, setRecepientData] = useState([]);
  const [pageNfo, setPageNfo] = useState({
    start: 0,
    end: 3,
    limit: 3,
    total: 6,
    current: 1,
  });
  const [dataPayload, setDataPayload] = useState();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    campaignName: '',
    file: null,
    contactGroupId: null,
    fetching: false,
    uploadSuccess: false,
    campaignType: 'immediate',
  });
  const [unsupportedPhones, setUnsupportedPhones] = useState([]);
  const dispatch = useDispatch();
  const { dataId } = useSelector((state) => {
    const service = state.services.services;
    const Meta = service.filter((s) => s.serviceName === 'Data')[0];
    return {
      dataId: Meta?._id,
    };
  });

  const createDataCampaign = useCallback(
    async (params = {}) => {
      await dispatch(createDataCampaignAction(params));
    },
    [dispatch]
  );

  const createSchedulledDataCampaign = useCallback(
    (payload) => dispatch(createSchedulledDataCampaignAction(payload)),
    [dispatch]
  );

  // const runDataCampaign = useCallback(
  //   async (params = {}) => {
  //     await dispatch(runCampaignAction(params));
  //   },
  //   [dispatch]
  // );
  const fetchUserWallet = useCallback(() => {
    dispatch(fetchWallet());
  }, [dispatch]);

  const mapStateToProps = ({ campaign, services }) => {
    return {
      serviceTypes: services.serviceTypes,
    };
  };

  const { serviceTypes } = useSelector(mapStateToProps);

  const {
    runningCampaign,
    successCreatingDataCampaign,
    errorCreatingDataCampaign,
    successCreatingSchedulledDataCampaign,
    creatingSchedulledDataCampaign,
    errorCreatingSchedulledDataCampaign,
    balance,
  } = useSelector((state) => {
    return {
      ...state.campaign,
      balance: state.wallet.walletNfo.balance,
    };
  });

  useUpdate(() => {
    if (successCreatingDataCampaign && price !== 0) {
      setShowSuccessModal(true);
      setRecepientData([]);
      fetchUserWallet();
    } else if (errorCreatingDataCampaign) {
      setError([true, errorCreatingDataCampaign || 'Error running campaign']);
    }
  }, [successCreatingDataCampaign, errorCreatingDataCampaign]);

  useUpdate(() => {
    if (successCreatingSchedulledDataCampaign && price !== 0) {
      setShowSchedule(false);
      setShowScheduleForLater(true);
      fetchUserWallet();
    } else {
      setShowSchedule(false);
    }
  }, [successCreatingSchedulledDataCampaign, errorCreatingSchedulledDataCampaign]);

  // convert data payload to csv
  const getCSV = (recipients) => {
    const csv = [
      ['countryCode', 'phoneNumber', 'dataPlan', 'cost', 'operator', 'operatorId'],
      ...recipients.map((r) => [
        '234',
        r.phone_number,
        r.selectedPlan.plan,
        r.selectedPlan.cost,
        r.selectedPlan.operator,
        r.selectedPlan.operatorId,
      ]),
    ]
      .map((e) => e.join(','))
      .join('\n');

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const recepientCsv = new File([blob], 'recipient.csv', { type: 'text/csv;charset=utf-8' });
    return recepientCsv;
  };

  const computeFinalPayload = () => {
    const campaignBody = {
      senderId: '',
      message: '',
    };
    const newpayload = new FormData();
    // if (formData.file) {
    const computedFile = getCSV(Object.values(currentRecipientBundle));
    const campaignPayload = {
      campaignScheduleType: 'createCampaign',
      campaignFile: computedFile,
      campaignName: formData.campaignName,
      companyId: user.companyId,
      serviceId: dataId,
      serviceType: serviceTypes?.find((d) => d?.serviceTypeName?.toLowerCase() === 'data')?._id,
      currencyType: 'NGN',
    };

    Object.keys(campaignPayload).map((key) => {
      newpayload.append(key, campaignPayload[key]);
      return null;
    });

    newpayload.append('campaignBody', JSON.stringify(campaignBody));
    newpayload.append('campaignType', formData.campaignType);
    newpayload.append('campaignTypeString', formData.campaignType);
    // }
    // if (formData.contactGroupId) {
    //   const campaignPayload = {
    //     campaignScheduleType: 'createCampaign',
    //     contactGroupId: formData.contactGroupId,
    //     campaignName: formData.campaignName,
    //     companyId: user.companyId,
    //     serviceId: dataId,
    //     serviceType: serviceTypes?.find((d) => d?.serviceTypeName?.toLowerCase() === 'data')?._id,
    //     currencyType: 'NGN',
    //   };

    //   Object.keys(campaignPayload).map((key) => {
    //     newpayload.append(key, campaignPayload[key]);
    //     return null;
    //   });

    //   newpayload.append('campaignBody', JSON.stringify(campaignBody));
    //   newpayload.append('campaignType', formData.campaignType);
    //   newpayload.append('campaignTypeString', formData.campaignType);
    // }

    return newpayload;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      readContact.length &&
      readContact.length - failedNums.length === Object.keys(currentRecipientBundle).length
    ) {
      const newPayload = computeFinalPayload();
      setShowCheckOut(true);
      setDataPayload(newPayload);
    } else {
      setError([true, 'Please select data plan for all phone numbers']);
    }
  };

  const handleSend = async () => {
    setShowCheckOut(false);
    createDataCampaign(dataPayload);
    // runDataCampaign(dataPayload);
    // setShowSuccessModal(true);
  };
  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
    if (name === 'file') {
      setFormData((v) => ({ ...v, [name]: value, contactGroupId: null }));
    }
  };
  // paginate detected phone number
  const handleNext = () => {
    const pageCount = Math.ceil(pageNfo.total / pageNfo.limit);
    if (
      currentRecipientBundle === undefined ||
      Object.values(currentRecipientBundle).length < pageNfo.limit
    ) {
      setError([true, 'Please select data plan for all phone numbers']);
    } else if (pageNfo.current < pageCount) {
      setPageNfo({
        ...pageNfo,
        start: pageNfo.start + pageNfo.limit,
        end: pageNfo.end + pageNfo.limit,
        current: pageNfo.current + 1,
      });
    }
  };
  // paginate detected phone number
  const handlePrev = () => {
    // const pageCount = Math.ceil(pageNfo.total / pageNfo.limit);
    if (pageNfo.current > 1) {
      setPageNfo({
        ...pageNfo,
        start: pageNfo.start - pageNfo.limit,
        end: pageNfo.end - pageNfo.limit,
        current: pageNfo.current - 1,
      });
    }
  };

  const FetchOperator = useCallback(async (params) => {
    setLoading(true);
    const resp = await APIfetchOperator(params);
    if (resp.data.message === 'ok') {
      return resp.data.data;
    }
  }, []);

  // data plan selection
  const handleDataPlanChange = ({ name, value, ext, id }) => {
    const newVal = value.split(' ')[0];
    setError([false, '']);
    if (value && value !== undefined) {
      setCurrentRecipientBundle((c) => ({
        ...c,
        [id]: {
          phone_number: ext.phoneNumber,
          selectedPlan: {
            operator: name,
            cost: newVal,
            operatorId: ext.operatorId,
            plan: ext.dataplan,
          },
        },
      }));
    }
  };

  // calculate price
  useUpdate(() => {
    if (currentRecipientBundle !== undefined) {
      const dt = Object.values(currentRecipientBundle);
      const newprice = dt.reduce((acc, d) => {
        return acc + parseFloat(d?.selectedPlan?.cost, 10);
      }, 0);
      setPrice(newprice.toFixed(2));
    }
  }, [currentRecipientBundle]);

  // Detect operator and match to data bundle
  useUpdate(() => {
    if (readContact?.length > 0) {
      const current = readContact.slice(pageNfo.start, pageNfo.end);
      const arr = [];
      const arrayOfPromise = current.map((r) => {
        return new Promise((resolve, reject) => {
          FetchOperator(r)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        });
      });

      Promise.all(
        arrayOfPromise.map((p) =>
          p.catch((err) => {
            setFailedNums((data) => [...data, '1']);
            if (err?.response?.data?.data?.error?.phoneNumber) {
              arr.push(err?.response?.data?.data?.error?.phoneNumber);
            }
          })
        )
      )
        .then((resp) => {
          setUnsupportedPhones(arr);
          const recepientNfo = resp
            ?.map((res, idx) => {
              if (res?.operator) {
                const { operator, plans } = res;
                return {
                  phoneNumber: current[idx].phoneNumber,
                  operator: operator.name,
                  plans,
                };
              }
              return null;
            })
            .filter((dt) => dt !== null);
          setRecepientData(() => [...recepientNfo]);
          setLoading(false);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log({ err });
        });
    }
  }, [readContact, pageNfo]);
  // Display phone number, operator, and select data plan
  const showAllRecepient = () => {
    if (loading) {
      return <Loader />;
    }
    return (
      recepientData &&
      recepientData
        .filter((r) => r !== null)
        .map((d, idx) => (
          <div className="row" key={idx}>
            <div className="col-3">{d.phoneNumber}</div>
            <div className="col-3">{d.operator}</div>
            <div className="col-3">
              {d.plans.length > 0 ? (
                <InputSelect
                  idx={d.phoneNumber}
                  name={d.operator}
                  label={d.operator}
                  meta={{
                    phoneNumber: d.phoneNumber,
                    operatorId: d.plans[0]?.operatorId,
                    dataplan: d.plans[0]?.plan,
                  }}
                  options={d.plans.map((a) => ({
                    label: a.plan,
                    value: `${a.cost} ${a.plan}`,
                  }))}
                  onChange={handleDataPlanChange}
                />
              ) : (
                'No data plan for this operator'
              )}
            </div>
            <div className="col-3">
              {currentRecipientBundle
                ? currentRecipientBundle[d.phoneNumber]?.selectedPlan?.plan
                : ''}
            </div>
          </div>
        ))
    );
  };
  // Read uploaded file
  useUpdate(async () => {
    if (formData.file) {
      const newPayload = new FormData();
      newPayload.append('campaignFile', formData?.file[0]);
      newPayload.append('serviceCategory', 'Data');
      try {
        const resp = await APIreadFile(newPayload);
        if (resp.data.success) {
          const { data } = resp.data;
          const newData = data?.map((d) => ({
            countryCode: d.countryCode || d['Country Code'],
            phoneNumber: 0 + d.Phonenumber,
          }));
          setPageNfo((p) => ({
            ...p,
            start: 0,
            end: newData.length < 3 ? newData.length : 3,
            limit: 3,
            total: newData.length,
            current: 1,
          }));
          setReadContact(newData);
          setFormData((f) => ({ ...f, uploadSuccess: true }));
        } else {
          throw resp?.data?.error;
        }
      } catch (err) {
        // console.log(err);
      }
    }
  }, [formData.file]);

  const handleShowSchedule = () => {
    if (pageNfo.end === pageNfo.total || formData.contactGroupId) {
      setFormData({ ...formData, campaignType: 'schedulled' });
      setShowSchedule(true);
    }
  };

  const handleSchedule = async (param) => {
    const newdataPayload = computeFinalPayload();
    Object.keys(param).map((key) => {
      newdataPayload.append(key, param[key]);
      return null;
    });

    createSchedulledDataCampaign(newdataPayload);
  };

  return (
    <>
      <Row>
        <Col md={8} lg={9} style={{ marginBottom: 20 }}>
          <form onSubmit={handleSubmit}>
            <div className="campaign-wrapper">
              <Row>
                <Col>
                  <Input
                    placeholder="Bulk Name"
                    label="Bulk Name"
                    name="campaignName"
                    validate="required"
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="upload-wrapper">
                  <Upload
                    icon="upload"
                    text="Upload Contacts"
                    validate="required"
                    hint={
                      <>
                        (see&nbsp;
                        <a href={dataCsv} download>
                          sample file
                        </a>
                        )
                      </>
                    }
                    name="file"
                    onChange={handleInputChange}
                  />
                  <div className="contact" onClick={() => setShowContacts(true)}>
                    <Icon icon="phone" margin={[0, 10, 0, 0]} />
                    Add from Contact
                  </div>
                </Col>
              </Row>
              <Row>
                <Col style={{ display: 'flex' }}>
                  {formData.file && (
                    <UploadResult
                      files={formData.file}
                      removeFile={() => setFormData((v) => ({ ...v, file: null }))}
                      upload={formData.uploadSuccess}
                    />
                  )}
                </Col>
              </Row>
              {formData.file === undefined && (
                <Row>
                  <Col>
                    <h4>{formData.contactGroupName}</h4>
                    <UploadResult
                      files={formData.file}
                      removeFile={() => setFormData((v) => ({ ...v, file: null }))}
                      upload={formData.uploadSuccess}
                    />
                  </Col>
                </Row>
              )}
              {readContact && (
                <>
                  <Row>
                    <Col>
                      <div style={{ fontWeight: 300 }}>Single Contact Plan</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <StyledAllRecepient>
                        <div>{showAllRecepient()}</div>
                        <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                          <Button
                            width="100%"
                            value="Prev"
                            group="outline"
                            disabled={pageNfo.current === 1}
                            onClick={handlePrev}
                          />
                          <Button
                            width="100%"
                            value="Next"
                            group="primary"
                            disabled={pageNfo.end === pageNfo.total}
                            onClick={handleNext}
                          />
                        </div>
                      </StyledAllRecepient>
                    </Col>
                  </Row>
                  {error[0] && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '12px',
                        color: 'red',
                      }}
                    >
                      {error[1]}
                    </div>
                  )}
                </>
              )}
              {unsupportedPhones.length > 0 && (
                <div className="px-4 mb-4">
                  <p className="text-danger">
                    Data bundles are unsupported for{' '}
                    {unsupportedPhones.length > 1 ? 'these' : 'this'} phone number
                    {unsupportedPhones.length > 1 ? 's' : ''}{' '}
                    {unsupportedPhones.map((dt) => `${dt}, `)}
                  </p>
                </div>
              )}
              <Row>
                <Col className="btn-container">
                  <Button
                    value="Send Now"
                    type="submit"
                    size="lg"
                    margin={isMobile ? 0 : [0, 10]}
                    width={isMobile ? '100%' : 200}
                    loading={runningCampaign}
                  />
                  <Button
                    value="Schedule for Later"
                    group="outline"
                    size="lg"
                    margin={isMobile ? 0 : [0, 10]}
                    width={isMobile ? '100%' : 200}
                    onClick={handleShowSchedule}
                  />
                </Col>
              </Row>
            </div>
          </form>
        </Col>
        <Col md={4} lg={3}>
          <WalletBalance price={price} />
        </Col>
      </Row>
      <SuccessModal
        show={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
          history.push(ROUTES.ALL_CAMPAIGN.path);
        }}
        headerText="Bulk Data launched successfully"
        subtitle="Your bulk data is being processed"
      />
      <CheckOut
        show={showCheckOut}
        onClose={() => setShowCheckOut(false)}
        execute={handleSend}
        price={price}
        disableWallet={price > balance}
      />
      <GroupContactModal
        show={showContacts}
        setShow={setShowContacts}
        type="Data"
        setPageNfo={setPageNfo}
        setReadContact={setReadContact}
        setFormData={setFormData}
      />
      <Finalize
        show={showScheduleForLater}
        onClose={() => {
          setShowScheduleForLater(false);
          history.push(ROUTES.ALL_CAMPAIGN.path);
        }}
        headerText="Your bulk data has been scheduled"
      />
      <Schedule
        show={showSchedule}
        setShow={setShowSchedule}
        handleSchedule={handleSchedule}
        loading={creatingSchedulledDataCampaign}
      />
    </>
  );
};

export default DataCampaign;
