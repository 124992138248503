import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import CompanyInfo from './features/CompanyInfo';
import RepresentativeInfo from './features/RepresentativeInfo';
import CheckIcon from '../../../assets/icons/check.svg';
import { ROUTES } from '../../../components/router/routes/routes';
import { APIsignUp } from '../../../api/backendRoutes';
import { AppContext } from '../../../utilities/context';
import { errorParser, rcFormat } from '../../../utilities/helper';
import countries from '../../../utilities/CountryList';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconContainer: {
    width: 50,
    height: 50,
    fontSize: '2rem',
  },
  labels: {
    marginTop: theme.spacing(4),
  },
}));

const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  const { partnerInfo } = useContext(AppContext);
  const [activeStep, setActiveStep] = useState(0);
  const [phoneCountry, setPhoneCountry] = useState('ng');
  const [formData, setFormData] = useState({
    businessType: '',
    businessName: '',
    businessRegNumber: '',
    country: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    terms: false,
    fetching: false,
    error: false,
    errorMessage: '',
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };
  const handleCountryChange = ({ name, value, code }) => {
    setFormData((v) => ({ ...v, [name]: value }));
    const selectedCountry = countries?.find((country) => country.label === value);
    setPhoneCountry(selectedCountry?.code.toLowerCase());
  };
  const handleSignUp = async () => {
    const signUpData = {
      companyId: partnerInfo?.id,
      state: partnerInfo?.state || 'N/A',
      companyAddress: 'N/A',
      companyState: partnerInfo?.state || 'N/A',
      identificationNum: partnerInfo?.id,
    };
    if (!rcFormat(formData.businessRegNumber)) {
      setFormData((f) => ({ ...f, error: true, errorMessage: 'Invalid Business Reg. NUmber' }));
      return false;
    }
    // eslint-disable-next-line no-console
    const { password, confirmPassword } = formData;
    if (password === confirmPassword) {
      Object.keys(formData).map((k) => {
        if (
          k !== 'terms' &&
          k !== 'confirmPassword' &&
          k !== 'error' &&
          k !== 'errorMessage' &&
          k !== 'fetching'
        ) {
          signUpData[k] = formData[k];
        }
        return null;
      });
      try {
        setFormData((d) => ({ ...d, fetching: true }));
        const resp = await APIsignUp(signUpData);
        if (resp.data.message === 'ok') {
          setFormData((d) => ({ ...d, fetching: false }));
          Toast.success('Check your Phone for OTP');
          history.push(ROUTES.VERIFY_OTP.path);
        } else {
          setFormData((d) => ({ ...d, fetching: false }));
          Toast.success(resp?.data?.error?.message);
          throw resp?.data?.error || 'error';
        }
      } catch (error) {
        setFormData((f) => ({
          ...f,
          error: true,
          fetching: false,
          errorMessage: errorParser(error) || 'error',
        }));
        // Toast.error(error.message);
      }
    } else {
      // Toast.error('Passwords do not match');
      setFormData((f) => ({ ...f, error: true, errorMessage: 'Passwords do not match' }));
    }
  };
  const pageTransition = {
    in: {
      opacity: 1,
      // y: 0,
      x: 0,
    },
    out: {
      opacity: 0,
      // y: '-2%',
      x: '-2%',
    },
  };
  return (
    <motion.div
      className={`${classes.root} position-relative signup-container`}
      initial="out"
      animate="in"
      exit="out"
      variants={pageTransition}
      transition={{ type: 'spring', duration: 0.8 }}
    >
      <div className="w-100 h-100">
        {activeStep === 0 ? (
          <CompanyInfo
            handleNext={handleNext}
            companyInfo={formData}
            handleCountryChange={handleCountryChange}
            handleInputChange={handleInputChange}
          />
        ) : activeStep === 1 ? (
          <RepresentativeInfo
            phoneCountry={phoneCountry}
            handleBack={handleBack}
            repInfo={formData}
            handleInputChange={handleInputChange}
            handleSignUp={handleSignUp}
          />
        ) : null}
      </div>
      <div className="progress-container">
        <div className="bar">
          <div className="line" />
          <div className={activeStep === 0 ? `status-right-outline` : 'status-right'}>2</div>
          <div className="status-left">
            {activeStep === 1 ? <img src={CheckIcon} alt="check" /> : 1}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SignUp;
